export class User {
  public attributes: UserAttributes;
  public username: string;
}

export class UserAttributes {
  public email: string;
  public name: string;
  public picture: string;
}

export class UserUpdateDTO {
  public email: string;
  public name: string;
  public avatar: string;
}

export class UserStats {
  public pollViews: number;
  public pollVotes: number;
  public pollShares: number;
}