export class ApiResponse<T extends object> {
  public success: boolean;
  public payload: T;

  public static error<T extends object>(): ApiResponse<T> {
    const response = new ApiResponse<T>();
    response.success = false;

    return response;
  }

  public static success<T extends object>(payload: T): ApiResponse<T> {
    const response = new ApiResponse<T>();
    response.success = true;
    response.payload = payload;

    return response;
  }
}
