import * as React from "react";
import * as ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import "./styles/index.scss";
import registerServiceWorker from "./registerServiceWorker";
import i18next from "./i18n";
import { Provider } from "../node_modules/mobx-react";
import { ApplicationViewModel } from "./view-models/ApplicationViewModel";
import { NotFoundScreen } from "./screens/home/screens/NotFoundScreen";

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <Provider applicationStore={new ApplicationViewModel()}>
        <Switch>
            <Route path="/:lng(en|ru)" component={App} />
            <Route component={NotFoundScreen}/>
        </Switch>
      </Provider>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root") as HTMLElement
);
registerServiceWorker();
