import Amplify from "aws-amplify";
import { runInAction } from "mobx";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "../node_modules/mobx-react";
import config from './config';
import { ApplicationViewModel } from "./view-models/ApplicationViewModel";


Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: config.IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: config.API_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: config.USER_POOL_WEBCLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.API_NAME,
        endpoint: config.API_ENDPOINT,
        region: config.API_REGION
      }
    ]
  }
});

interface IMatchParams {}

interface IProps extends RouteComponentProps<IMatchParams> {
  applicationStore?: ApplicationViewModel;
}

@observer(["applicationStore"])
class App extends React.Component<IProps> {
  constructor(props: any) {
    super(props);

    this.props.applicationStore!.populateCurrentUser();
  }

  private loadGoogleSDK = () => {
    window["gapi"].load('auth2', () => {
      window["auth2"] = window["gapi"].auth2.init({
        client_id: config.GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      runInAction(() => {
        this.props.applicationStore!.googleInit = true;
      })
    });
  }

  componentWillMount() {
    this.loadGoogleSDK();
  }

  public render() {
    return this.props.applicationStore!.appLoaded ? (
      <div>
        Pipe Spyder
      </div>
    ) : (
      <div>
        Pipe Spyder
      </div>
    );
  }
}

export default withRouter(App);
