import * as i18n from 'i18next'
import * as LanguageDetector from 'i18next-browser-languagedetector'
import * as Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
 
const i18next = i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(reactI18nextModule)
  .init({
    whitelist: ['en', 'ru'],
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/i18n/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
 
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
 
    nsSeparator: false,
    keySeparator: false, // we use content as keys
 
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
 
    react: {
      wait: true
    }
  });
 

export default i18next;