import { withI18n, withNamespaces } from "react-i18next";
import { i18n, TranslationFunction } from "i18next";

export interface ITranslated {
  t: TranslationFunction;
  i18n: i18n;
}

export function translated<P>(target: React.ComponentType<P>): any {
  return withNamespaces()(target as any)
}