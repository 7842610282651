import { Auth } from 'aws-amplify';
import { observable, runInAction } from "mobx";
import { User } from "../models/User";
import { netwrokingService } from "../services/NetworkingService";

export class ApplicationViewModel {
  @observable public appLoaded: boolean = false;

  @observable public error: string | null = null;
  @observable public showErrorBanner: boolean = false;

  @observable public currentUser: User | null = null;
  @observable public theme: "default" | "white";

  @observable public emailToConfirm: string = "";

  @observable public googleInit: boolean = false;

  private errorBannerTimeout: NodeJS.Timeout | null = null;

  constructor() {
    this.theme = "default"

    netwrokingService.on("error", () => {
      console.log("API 500 error");
      runInAction(() => {
        this.error = "Server error, please try again later"
        this.showErrorBanner = true;

        if (this.errorBannerTimeout) {
          clearTimeout(this.errorBannerTimeout)
        }

        this.errorBannerTimeout = setTimeout(() => {
          this.showErrorBanner = false;
          this.errorBannerTimeout = null;
        }, 4000)
      })
    })
  }

  public populateCurrentUser() {
    return Auth.currentAuthenticatedUser().then((user) => {
      runInAction(() => {
        
        if (user.authenticationFlowType) {
          // email login, user has all properties
          this.currentUser = user
        } else {
          // federated login, transform user object before saving
          let appUser = new User();
          appUser.username = user.email;
          appUser.attributes = {
            name: user.name,
            email: user.email,
            picture: ""
          }
          this.currentUser = appUser
        }
        this.appLoaded = true
      })

      return Promise.resolve()

    }).catch(error => {
      runInAction(() => {
        this.currentUser = null
        this.appLoaded = true
      })
    })
  }

  public logout() {
    return Auth.signOut().then(() => {
      runInAction(() => {
        this.currentUser = null
      })
    })
  }
}